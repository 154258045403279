const DB_APP_MAIN = "meta-world-database"
const DB_UNITY_CACHE = "UnityCache"
const DB_IDBFS = "/idbfs"

const OBJECT_STORE_CACHE = "cache"
const INDEX_CACHE = "name"
const KEY_BUILD_NO = "BuildNo"

const OBJECT_STORE_FILE_DATA = "FILE_DATA"
const UNITY_CACHE_PATH = "UnityCache"

let buildNoCache: string | null = null

type BuildInfo = {
  [INDEX_CACHE]: string
  [KEY_BUILD_NO]: string
}

async function get<T = any>(db: IDBDatabase, docuname: string, id: IDBValidKey): Promise<T> {
  return new Promise((resolve, reject) => {
    const docs = db.transaction(docuname).objectStore(docuname)
    const req = docs.get(id)
    req.onsuccess = () => resolve(req.result as T)
    req.onerror = reject
  })
}

async function getAllKeys(db: IDBDatabase, docuname: string): Promise<IDBValidKey[]> {
  return new Promise((resolve, reject) => {
    const docs = db.transaction(docuname).objectStore(docuname)
    const req = docs.getAllKeys()
    req.onsuccess = () => resolve(req.result)
    req.onerror = reject
  })
}

async function del(db: IDBDatabase, docuname: string, key: IDBValidKey): Promise<undefined> {
  return new Promise((resolve, reject) => {
    const docs = db.transaction(docuname, "readwrite").objectStore(docuname)
    const req = docs.delete(key)
    req.onsuccess = () => resolve(undefined)
    req.onerror = reject
  })
}

async function put(db: IDBDatabase, docuname: string, obj: any): Promise<IDBValidKey> {
  return new Promise((resolve, reject) => {
    const docs = db.transaction(docuname, "readwrite").objectStore(docuname)
    const req = docs.put(obj)
    req.onsuccess = () => resolve(req.result)
    req.onerror = reject
  })
}

// データキャッシュ用 indexedDB を開く、または作成
function openIndexedDB(): Promise<IDBDatabase> {
  const dbp = new Promise<IDBDatabase>((resolve, reject) => {
    const req = window.indexedDB.open(DB_APP_MAIN)
    req.onsuccess = () => resolve(req.result)
    req.onerror = reject
    req.onupgradeneeded = () => {
      const db = req.result
      db.createObjectStore(OBJECT_STORE_CACHE, { keyPath: INDEX_CACHE })
    }
  }).then((d) => {
    d.onerror = (e) => console.error("fails to indexedDB operation", e)
    return d
  })
  return dbp
}

// UnityCache のDB全削除
async function deleteUnityCacheDB(): Promise<undefined> {
  console.log("deleteUnityCacheDB")
  return new Promise((resolve, reject) => {
    const req = window.indexedDB.deleteDatabase(DB_UNITY_CACHE)
    req.onsuccess = () => resolve(undefined)
    req.onerror = reject
  })
}

// IDBFSを取得
async function getUnityCacheIDBDFS(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const req = window.indexedDB.open(DB_IDBFS)
    req.onsuccess = () => resolve(req.result)
    req.onerror = reject
  })
}

// localパスのJSONを読み込み
async function loadJSON<T = any>(path: string): Promise<T> {
  const response = await fetch(path)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const json = await response.json()
  return json as T
}

export async function getBuildNo(): Promise<string | null> {
  if (buildNoCache == null) {
    const buildNoJson = await loadJSON<BuildInfo>("./build.json?t=" + Math.floor(Date.now() / 1000))
    if (buildNoJson) {
      buildNoCache = buildNoJson.BuildNo
    }
  }
  return buildNoCache
}

export async function clearCache(buildNo: string): Promise<undefined> {
  const db = await openIndexedDB()
  const buildNoInfo = await get<BuildInfo>(db, OBJECT_STORE_CACHE, KEY_BUILD_NO)
  console.log("cached BuildInfo:", buildNoInfo)

  if (buildNoInfo?.BuildNo == buildNo) {
    return
  }

  if (buildNoInfo != null) {
    // IDBFS から `UnityCache` を含むデータを削除
    const idbfs = await getUnityCacheIDBDFS()
    const keys = await getAllKeys(idbfs, OBJECT_STORE_FILE_DATA)
    for (let i = 0; i < keys.length; i++) {
      if (typeof keys[i] === "string" && (<string>keys[i]).indexOf(UNITY_CACHE_PATH) !== -1) {
        await del(idbfs, OBJECT_STORE_FILE_DATA, keys[i])
        console.log(`deleted cache ${DB_IDBFS}: ${keys[i]}`)
      }
    }
    // UnityCacheDB を全て削除
    await deleteUnityCacheDB()
  }

  // ビルド番号を保存する
  const newBuildInfo: BuildInfo = {
    [INDEX_CACHE]: KEY_BUILD_NO,
    [KEY_BUILD_NO]: buildNo,
  }
  await put(db, OBJECT_STORE_CACHE, newBuildInfo)
  console.log("upgraded BuildInfo:", newBuildInfo)
}
