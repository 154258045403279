import App from "./app.svelte"

// split chunk
import("hls.js").then((mod) => {
  window.Hls = mod.default
})

const app = new App({
  target: document.getElementById("main"),
})

export default app
