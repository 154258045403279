import { getStorage, ref, getDownloadURL } from "firebase/storage"

export function initialize() {
  window.unityBridge.storage = new StorageBridge()
}

class StorageBridge {
  public getDownloadURL(path: string, callbackObject: string, callbackMethod: string) {
    const storage = getStorage()
    getDownloadURL(ref(storage, path))
      .then((url) => window.unityInstance.SendMessage(callbackObject, callbackMethod, url))
      .catch(console.error)
  }

  public async Download(uri: string, fileName: string) {
    try {
      const image = await fetch(uri)
      const blob = await image.blob()

      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    } catch (e) {
      console.error(e)
    }
  }
}
